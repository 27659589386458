export function determineStatClass(stat) {
    switch (stat) {
        case -1:
            return 'stat-baja';
        case 1:
            return 'stat-sube';
        default:
            return '';
    }
}

export function determineStatText(stat) {
    switch (stat) {
        case -1:
            return 'BAJA';
        case 1:
            return 'SUBE';
        default:
            return '';
    }
}
