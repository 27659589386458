import React from 'react';
import { useState } from 'react';
import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    InputGroup,
    Row,
} from 'react-bootstrap';
import { BsXLg } from 'react-icons/bs';
import '../assets/style.css';
import {
    naturalezas1,
    naturalezas2,
    naturalezas3,
    naturalezas4,
    naturalezas5,
    naturalezas6,
} from '../data/constantes';
import TableNatures from '../components/TableNatures';

export default function Naturalezas() {
    const [filtro, setFiltro] = useState('');
    const [filtroMinus, setFiltroMinus] = useState('');

    const filtrar = (filtro) => {
        setFiltro(filtro);
        setFiltroMinus(filtro.toLowerCase());
    };

    return (
        <Container fluid className='mt-2' id='main-naturalezas'>
            <Form onReset={() => filtrar('')} id='form-naturalezas'>
                <div className='d-flex'>
                    <InputGroup>
                        <FormControl
                            placeholder='Filtrar naturalezas'
                            aria-label='Filtrar naturalezas'
                            value={filtro}
                            onChange={(event) => filtrar(event.target.value)}
                        />
                        <Button variant='outline-secondary' type='reset'>
                            <BsXLg className='m-auto' />
                        </Button>
                    </InputGroup>
                    <a
                        className='ms-2'
                        target='_blank'
                        href='https://www.wikidex.net/wiki/Naturaleza'
                        rel='noreferrer'
                    >
                        <Button variant='outline-primary' type='button'>
                            Wikidex
                        </Button>
                    </a>
                </div>
            </Form>
            <Row>
                <Col>
                    <TableNatures natures={naturalezas1} filter={filtroMinus} />
                </Col>
                <Col>
                    <TableNatures natures={naturalezas2} filter={filtroMinus} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableNatures natures={naturalezas3} filter={filtroMinus} />
                </Col>
                <Col>
                    <TableNatures natures={naturalezas4} filter={filtroMinus} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableNatures natures={naturalezas5} filter={filtroMinus} />
                </Col>
                <Col>
                    <TableNatures natures={naturalezas6} filter={filtroMinus} />
                </Col>
            </Row>
        </Container>
    );
}
