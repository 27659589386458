import React from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { evoluciones } from '../data/constantes';
export default function Reglas() {
    return (
        <Container fluid className='max-width-1600'>
            <Row xs={1} sm={2} className='mt-2'>
                <Col>
                    <h4>Lista de niveles</h4>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th className='text-end'>Líder</th>
                                <th>Nivel</th>
                                <th className='text-end'>Líder</th>
                                <th>Nivel</th>
                                <th className='text-end'>Líder</th>
                                <th>Nivel</th>
                                <th className='text-end'>Líder</th>
                                <th>Nivel</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-end'>Roco:</td>
                                <td>14</td>
                                <td className='text-end'>Gardenia:</td>
                                <td>22</td>
                                <td className='text-end'>Fantina:</td>
                                <td>30</td>
                                <td className='text-end'>Brega:</td>
                                <td>32</td>
                            </tr>
                            <tr>
                                <td className='text-end'>Mananti:</td>
                                <td>37</td>
                                <td className='text-end'>Acerón:</td>
                                <td>41</td>
                                <td className='text-end'>Inverna:</td>
                                <td>44</td>
                                <td className='text-end'>Lectro:</td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <td className='text-end'>Alecrán:</td>
                                <td>53</td>
                                <td className='text-end'>Bruno:</td>
                                <td>55</td>
                                <td className='text-end'>Agatha:</td>
                                <td>57</td>
                                <td className='text-end'>Lance:</td>
                                <td>59</td>
                            </tr>
                            <tr>
                                <td className='text-end' colSpan={7}>
                                    Campeón:
                                </td>
                                <td>62</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Card className='mb-auto'>
                        <Card.Body>
                            <Card.Title>Reglas de ruta</Card.Title>
                            <ul>
                                <li>
                                    Solo se puede capturar un pokémon por ruta o
                                    zona, que debe ser el primero.
                                </li>
                                <li>
                                    Si el pokémon de la ruta muere o tú escapas,
                                    no se podrá capturar en esa ruta. Esto no es
                                    así si el pokémon escapa.
                                </li>
                                <li>
                                    Si sale un legendario como primer pokémon de
                                    ruta, se puede escapar y capturar otro.
                                </li>
                                <li>
                                    Un shiny no cuenta como primer pokémon de
                                    ruta. Por lo tanto, se puede capturar
                                    libremente.
                                </li>
                                <li>
                                    Si ya tienes la evolución de algún pokemon
                                    de ruta que te salga como primer poémon de
                                    ruta, puedes atrapar ese siempre y cuando lo
                                    tengas vivo. También puedes pasar del
                                    Pokémon y capturar al siguiente.
                                </li>
                            </ul>
                            <Card.Title>Otras reglas</Card.Title>
                            <ul>
                                <li>
                                    En combte, se puede curar en combate
                                    indefinidamente.
                                </li>
                                <li>
                                    El nivel del pokémon no puede superar al
                                    nivel máximo del líder de gym siguiente, a
                                    no ser que esto ocurra en su combate.
                                </li>
                                <li>
                                    Si un pokémon cae, no se podrá volver a
                                    utilizar.
                                </li>
                                <li>
                                    Los pokémon capturados deben tener mote.
                                </li>
                                <li>
                                    Para los intercambios, se puede obtener
                                    mediante trampas el pokémon solicitado, pero
                                    sacrificando uno de los tuyos.
                                </li>
                                <li> LOS INTERCAMBIOS SON ALEATORIOS.</li>
                                <li>No se pueden comprar objetos curativos.</li>
                                <li>
                                    Se pueden usar pokémon muertos o capturar
                                    los necesarios para las MO.
                                </li>
                                <li>
                                    El locke empieza cuando te enseñen a
                                    capturar y acaba cuando se consigan las ocho
                                    medallas.
                                </li>
                            </ul>
                            <Card.Text>
                                Se recomienda agregar los carameloraro con{' '}
                                <a
                                    href='https://projectpokemon.org/home/files/file/1-pkhex/'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    PKHeX
                                </a>
                                .
                            </Card.Text>
                            <Card.Text>
                                Los pokémon son hasta cuarta y los{' '}
                                <a
                                    href='https://www.wikidex.net/wiki/Lista_de_movimientos_por_generaci%C3%B3n#Movimientos_de_la_cuarta_generaci%C3%B3n'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    movimientos están sin actualizar
                                </a>
                                . No existe el tipo Hada.
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <h5 className='mt-2'>
                        Pokémon solicitados para intercambios
                    </h5>
                    <ul className='list-group list-group-horizontal'>
                        <li className='list-group-item'>Machop</li>
                        <li className='list-group-item'>Buizel</li>
                        <li className='list-group-item'>Medicham</li>
                        <li className='list-group-item'>Finneon</li>
                    </ul>
                </Col>
                <Col>
                    <h5>Pokémon con cambios en su evolución:</h5>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Pre</th>
                                <th>Evolución</th>
                                <th>Método</th>
                            </tr>
                        </thead>
                        <tbody>
                            {evoluciones.map((e, i) => (
                                <tr key={i}>
                                    <td>{e.pre}</td>
                                    <td>{e.evo}</td>
                                    <td>{e.method}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}
