import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
    Button,
    Col,
    Container,
    FormControl,
    Row,
    Table,
} from 'react-bootstrap';
import {
    rutas1 as Rutas1,
    rutas2 as Rutas2,
    rutas3 as Rutas3,
} from '../data/constantes';

export default function Rutas() {
    const [rutas1, setRutas1] = useState(new Map());
    const [rutas2, setRutas2] = useState(new Map());
    const [rutas3, setRutas3] = useState(new Map());

    useEffect(() => {
        const rutas1 = new Map(JSON.parse(localStorage.getItem('rutas-1')));
        const rutas2 = new Map(JSON.parse(localStorage.getItem('rutas-2')));
        const rutas3 = new Map(JSON.parse(localStorage.getItem('rutas-3')));
        setRutas1(rutas1.size !== 0 ? rutas1 : Rutas1);
        setRutas2(rutas2.size !== 0 ? rutas2 : Rutas2);
        setRutas3(rutas3.size !== 0 ? rutas3 : Rutas3);
    }, []);

    const guardarRutas = (nombre, rutas) => {
        localStorage.setItem(nombre, JSON.stringify(Array.from(rutas)));
    };

    const actualizar1 = (ruta, pokemon) => {
        rutas1.set(ruta, { ...rutas1.get(ruta), pokemon });
        setRutas1(new Map(rutas1));
        guardarRutas('rutas-1', rutas1);
    };

    const matar1 = (ruta) => {
        const datos = { ...rutas1.get(ruta) };
        datos.muerto = !datos.muerto;
        rutas1.set(ruta, datos);
        setRutas1(new Map(rutas1));
        guardarRutas('rutas-1', rutas1);
    };

    const actualizar2 = (ruta, pokemon) => {
        rutas2.set(ruta, { ...rutas2.get(ruta), pokemon });
        setRutas2(new Map(rutas2));
        guardarRutas('rutas-2', rutas2);
    };

    const matar2 = (ruta) => {
        const datos = { ...rutas2.get(ruta) };
        datos.muerto = !datos.muerto;
        rutas2.set(ruta, datos);
        setRutas2(new Map(rutas2));
        guardarRutas('rutas-2', rutas2);
    };

    const actualizar3 = (ruta, pokemon) => {
        rutas3.set(ruta, { ...rutas3.get(ruta), pokemon });
        setRutas3(new Map(rutas3));
        guardarRutas('rutas-3', rutas3);
    };

    const matar3 = (ruta) => {
        const datos = { ...rutas3.get(ruta) };
        datos.muerto = !datos.muerto;
        rutas3.set(ruta, datos);
        setRutas3(new Map(rutas3));
        guardarRutas('rutas-3', rutas3);
    };

    return (
        <Container fluid className='max-width-1600'>
            <Row>
                <Col as={Table} className='me-2 mb-auto mx-auto tabla-rutas'>
                    <thead>
                        <tr>
                            <th className='text-end'>Lugar</th>
                            <th className='text-center'>Pokémon</th>
                            <th>Perdido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(rutas1).map(([r, d]) => (
                            <tr key={r}>
                                <td className='celda-nombre'>
                                    {r.toLowerCase()}
                                </td>
                                <td>
                                    <FormControl
                                        value={d.pokemon}
                                        onChange={(e) =>
                                            actualizar1(r, e.target.value)
                                        }
                                        disabled={d.muerto}
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant={
                                            d.muerto
                                                ? 'danger'
                                                : 'outline-danger'
                                        }
                                        onClick={() => matar1(r)}
                                    >
                                        Perdido
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Col>
                <Col as={Table} className='mb-auto mx-auto tabla-rutas'>
                    <thead>
                        <tr>
                            <th className='text-end'>Lugar</th>
                            <th className='text-center'>Pokémon</th>
                            <th>Perdido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(rutas2).map(([r, d]) => (
                            <tr key={r}>
                                <td className='celda-nombre'>
                                    {r.toLowerCase()}
                                </td>
                                <td>
                                    <FormControl
                                        value={d.pokemon}
                                        onChange={(e) =>
                                            actualizar2(r, e.target.value)
                                        }
                                        disabled={d.muerto}
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant={
                                            d.muerto
                                                ? 'danger'
                                                : 'outline-danger'
                                        }
                                        onClick={() => matar2(r)}
                                    >
                                        Perdido
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Col>
                <Col as={Table} className='mb-auto mx-auto tabla-rutas'>
                    <thead>
                        <tr>
                            <th className='text-end'>Lugar</th>
                            <th className='text-center'>Pokémon</th>
                            <th>Perdido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(rutas3).map(([r, d]) => (
                            <tr key={r}>
                                <td className='celda-nombre'>
                                    {r.toLowerCase()}
                                </td>
                                <td>
                                    <FormControl
                                        value={d.pokemon}
                                        onChange={(e) =>
                                            actualizar3(r, e.target.value)
                                        }
                                        disabled={d.muerto}
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant={
                                            d.muerto
                                                ? 'danger'
                                                : 'outline-danger'
                                        }
                                        onClick={() => matar3(r)}
                                    >
                                        Perdido
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Col>
            </Row>
        </Container>
    );
}
