import React from 'react';
import { determineStatClass } from '../data/functions';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { Table } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';

export default function TableNatures({ natures, filter }) {
    const [filtered, setFiltered] = useState(natures);

    useEffect(() => {
        setFiltered(natures.filter((n) => n.search.includes(filter)));
    }, [natures, filter]);

    return (
        <Table bordered className='mt-2 tabla-naturalezas mx-auto'>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Ataque</th>
                    <th>Defensa</th>
                    <th>Ataque especial</th>
                    <th>Defensa especial</th>
                    <th>Velocidad</th>
                </tr>
            </thead>
            <tbody>
                {filtered.map((n) => (
                    <tr key={n.name}>
                        <td>
                            {n.name.charAt(0).toUpperCase() + n.name.slice(1)}
                        </td>
                        {n.stats.map((s, i) => (
                            <td
                                key={`${n.name}_stat_${i}`}
                                className={determineStatClass(s)}
                            >
                                {s === -1 && (
                                    <>
                                        <BsArrowDown /> BAJA
                                    </>
                                )}
                                {s === 1 && (
                                    <>
                                        <BsArrowUp /> SUBE
                                    </>
                                )}
                                {s === 0 && '--'}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
