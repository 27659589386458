import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NavigationBar() {
    return (
        <Navbar bg='dark' variant='dark'>
            <Container fluid>
                <Navbar.Brand as={Link} to='/'>
                    Randomlocke ninis
                </Navbar.Brand>
                <Nav className='me-auto'>
                    <Nav.Link as={Link} to='/naturalezas'>
                        Naturalezas
                    </Nav.Link>
                    <Nav.Link as={Link} to='/reglas'>
                        Información
                    </Nav.Link>
                    <Nav.Link as={Link} to='/rutas'>
                        Rutas
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}
