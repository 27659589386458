import Evolucion from './Evolucion';
import Naturaleza from './Naturaleza';

// neutrales
export const naturalezas1 = [
    new Naturaleza('Fuerte', 'hardy', [0, 0, 0, 0, 0], 'fuerte'),
    new Naturaleza('Dócil', 'docile', [0, 0, 0, 0, 0], 'docil'),
    new Naturaleza('Tímida', 'bashful', [0, 0, 0, 0, 0], 'timida'),
    new Naturaleza('Rara', 'quirky', [0, 0, 0, 0, 0], 'rara'),
    new Naturaleza('Seria', 'serious', [0, 0, 0, 0, 0], 'seria'),
];

// desfavorable en ataque
export const naturalezas2 = [
    new Naturaleza('Osada', 'bold', [-1, 1, 0, 0, 0], 'osada'),
    new Naturaleza('Modesta', 'modest', [-1, 0, 1, 0, 0], 'modesta'),
    new Naturaleza('Serena', 'calm', [-1, 0, 0, 1, 0], 'serena'),
    new Naturaleza('Miedosa', 'timid', [-1, 0, 0, 0, 1], 'miedosa'),
];

// desfavorable en defensa
export const naturalezas3 = [
    new Naturaleza('Huraña', 'lonely', [1, -1, 0, 0, 0], 'huraña'),
    new Naturaleza('Afable', 'mild', [0, -1, 1, 0, 0], 'afable'),
    new Naturaleza('Amable', 'gentle', [0, -1, 0, 1, 0], 'amable'),
    new Naturaleza('Activa', 'hasty', [0, -1, 0, 0, 1], 'activa'),
];

// desfarable en at especial
export const naturalezas4 = [
    new Naturaleza('Firme', 'adamant', [1, 0, -1, 0, 0], 'firme'),
    new Naturaleza('Agitada', 'impish', [0, 1, -1, 0, 0], 'agitada'),
    new Naturaleza('Cauta', 'careful', [0, 0, -1, 1, 0], 'cauta'),
    new Naturaleza('Alegre', 'jolly', [0, 0, -1, 0, 1], 'alegre'),
];

// desfavorable en def especial
export const naturalezas5 = [
    new Naturaleza('Pícara', 'naughty', [1, 0, 0, -1, 0], 'picara'),
    new Naturaleza('Floja', 'lax', [0, 1, 0, -1, 0], 'floja'),
    new Naturaleza('Alocada', 'rash', [0, 0, 1, -1, 0], 'alocada'),
    new Naturaleza('Ingenua', 'naive', [0, 0, 0, -1, 1], 'ingenua'),
];

// desfavorable en velocidad
export const naturalezas6 = [
    new Naturaleza('Audaz', 'brave', [1, 0, 0, 0, -1], 'audaz'),
    new Naturaleza('Plácida', 'relaxed', [0, 1, 0, 0, -1], 'placida'),
    new Naturaleza('Mansa', 'quiet', [0, 0, 1, 0, -1], 'mansa'),
    new Naturaleza('Grosera', 'sassy', [0, 0, 0, 1, -1], 'grosera'),
];

export const evoluciones = [
    new Evolucion('Poliwhirl', 'Politoed', 'Subir nivel con Roca del Rey'),
    new Evolucion('KADABRA', 'ALAKAZAM', 'Al nivel 37'),
    new Evolucion('MACHOKE', 'MACHAMP', 'Al nivel 37'),
    new Evolucion('GRAVELER', 'GOLEM', 'Al nivel 37'),
    new Evolucion('SLOWPOKE', 'SLOWKING', 'Usando una Piedra Agua'),
    new Evolucion('HAUNTER', 'GENGAR', 'Al nivel 37'),
    new Evolucion('ONIX', 'STEELIX', 'Subir nivel con Rev.metálico'),
    new Evolucion('LICKITUNG', 'LICKILICKY', 'Al nivel 33'),
    new Evolucion('RHYDON', 'RHYPERIOR', 'Subir nivel con Protector'),
    new Evolucion('TANGELA', 'TANGROWTH', 'Al nivel 33'),
    new Evolucion('SEADRA', 'KINGDRA', 'Subir nivel con Escamadragón'),
    new Evolucion('SCYTHER', 'SCIZOR', 'Subir nivel con Rev.metálico'),
    new Evolucion('ELECTABUZZ', 'ELECTIVIRE', 'Subir nivel con Electrizador'),
    new Evolucion('MAGMAR', 'MAGMORTAR', 'Subir nivel con Magmatizador'),
    new Evolucion('PORYGON', 'PORYGON2', 'Subir nivel con Mejora'),
    new Evolucion('AIPOM', 'AMBIPOM', 'Al nivel 32'),
    new Evolucion('YANMA', 'YANMEGA', 'Al nivel 33'),
    new Evolucion('PILOSWINE', 'MAMOSWINE', 'Al nivel 45'),
    new Evolucion('PORYGON2', 'PORYGON-Z', 'Subir nivel con Discoxtraño'),
    new Evolucion('DUSCLOPS', 'DUSKNOIR', 'Subir nivel con Telaterrible'),
    new Evolucion('CLAMPERL', 'HUNTAIL', 'Subir nivel con Diente Mar.'),
    new Evolucion('CLAMPERL', 'GOREBYSS', 'Subir nivel con Escama Mar.'),
    new Evolucion('BONSLY', 'SUDOWOODO', 'Al nivel 17'),
    new Evolucion('MIME', 'MR. MIME', 'Al nivel 18'),
];

export const rutas1 = new Map([
    ['RUTA 201', { pokemon: '', muerto: false }],
    ['RUTA 202', { pokemon: '', muerto: false }],
    ['RUTA 203', { pokemon: '', muerto: false }],
    ['RUTA 204', { pokemon: '', muerto: false }],
    ['RUTA 205', { pokemon: '', muerto: false }],
    ['RUTA 206', { pokemon: '', muerto: false }],
    ['RUTA 207', { pokemon: '', muerto: false }],
    ['RUTA 208', { pokemon: '', muerto: false }],
    ['RUTA 209', { pokemon: '', muerto: false }],
    ['RUTA 210', { pokemon: '', muerto: false }],
    ['RUTA 211', { pokemon: '', muerto: false }],
    ['RUTA 212', { pokemon: '', muerto: false }],
    ['RUTA 213', { pokemon: '', muerto: false }],
    ['RUTA 214', { pokemon: '', muerto: false }],
    ['RUTA 215', { pokemon: '', muerto: false }],
    ['RUTA 216', { pokemon: '', muerto: false }],
    ['RUTA 217', { pokemon: '', muerto: false }],
    ['RUTA 218', { pokemon: '', muerto: false }],
    ['RUTA 219', { pokemon: '', muerto: false }],
    ['RUTA 220', { pokemon: '', muerto: false }],
    ['RUTA 221', { pokemon: '', muerto: false }],
]);

export const rutas2 = new Map([
    ['RUTA 222', { pokemon: '', muerto: false }],
    ['RUTA 223', { pokemon: '', muerto: false }],
    ['RUTA 224', { pokemon: '', muerto: false }],
    ['RUTA 225', { pokemon: '', muerto: false }],
    ['RUTA 226', { pokemon: '', muerto: false }],
    ['RUTA 227', { pokemon: '', muerto: false }],
    ['RUTA 228', { pokemon: '', muerto: false }],
    ['RUTA 229', { pokemon: '', muerto: false }],
    ['RUTA 230', { pokemon: '', muerto: false }],
    ['BOSQUE VETUSTO', { pokemon: '', muerto: false }],
    ['CALLE VICTORIA', { pokemon: '', muerto: false }],
    ['CIUDAD CANAL', { pokemon: '', muerto: false }],
    ['CIUDAD MARINA', { pokemon: '', muerto: false }],
    ['CIUDAD PRADERA', { pokemon: '', muerto: false }],
    ['CIUDAD VETUSTA', { pokemon: '', muerto: false }],
    ['CUEVA EXTRAVÍO', { pokemon: '', muerto: false }],
    ['CUEVA RETORNO', { pokemon: '', muerto: false }],
    ['FORJA FUEGO', { pokemon: '', muerto: false }],
    ['FUENTE DESPEDIDA', { pokemon: '', muerto: false }],
    ['GRAN PANTANO', { pokemon: '', muerto: false }],
    ['ISLA HIERRO', { pokemon: '', muerto: false }],
]);

export const rutas3 = new Map([
    ['JARDÍN TROFEO', { pokemon: '', muerto: false }],
    ['LAGO VERAZ', { pokemon: '', muerto: false }],
    ['LAGO VALOR', { pokemon: '', muerto: false }],
    ['LAGO AGUDEZA', { pokemon: '', muerto: false }],
    ['LIGA POKÉMON', { pokemon: '', muerto: false }],
    ['MINA PIRITA', { pokemon: '', muerto: false }],
    ['MINA RUIMANÍACO', { pokemon: '', muerto: false }],
    ['MONTAÑA DURA', { pokemon: '', muerto: false }],
    ['MONTE CORONA', { pokemon: '', muerto: false }],
    ['ORILLA VALOR', { pokemon: '', muerto: false }],
    ['ORILLA AGUDEZA', { pokemon: '', muerto: false }],
    ['PUEBLO HOJAVERDE', { pokemon: '', muerto: false }],
    ['PUEBLO CAELESTIS', { pokemon: '', muerto: false }],
    ['PUERTA PIRITA', { pokemon: '', muerto: false }],
    ['RUINAS SOSIEGO', { pokemon: '', muerto: false }],
    ['SENDA DESOLADA', { pokemon: '', muerto: false }],
    ['TEMPLO PUNTANEVA', { pokemon: '', muerto: false }],
    ['TÚNEL MANÍACO', { pokemon: '', muerto: false }],
    ['VALLE EÓLICO', { pokemon: '', muerto: false }],
    ['VIEJA MANSIÓN', { pokemon: '', muerto: false }],
]);
