import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NavigationBar from './components/Navbar';
import Naturalezas from './pages/Naturalezas';
import Reglas from './pages/Reglas';
import Rutas from './pages/Rutas';

function App() {
    return (
        <>
            <BrowserRouter>
                <NavigationBar />
                <Routes>
                    <Route path='/' element={<Naturalezas />} />
                    <Route path='/naturalezas' element={<Naturalezas />} />
                    <Route path='/reglas' element={<Reglas />} />
                    <Route path='/rutas' element={<Rutas />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
